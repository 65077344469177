<script setup lang="ts">
import { ref } from 'vue';
import { FileRejectReason, useDropzone } from "vue3-dropzone";
const showRejectionMessage = ref(false);
;
const emit = defineEmits<{(e: 'onFile', data: FormData): void}>()

function uploadFiles(files: string[]) {
	const data = new FormData(); // pass data as a form
	for (let i = 0; i < files.length; i++) {
		// append files as array to the form, feel free to change the array name
		data.append("files[]", files[i]);
	}

	emit("onFile", data);
}

function onDrop(acceptFiles: any[], rejectReasons: FileRejectReason[]) {
	if (rejectReasons.length === 0) {
		// reject
		// return
		console.log("what is this specario?");
	}

	if (rejectReasons.length != 0) {
		showRejectionMessage.value = true;
		setTimeout(() => showRejectionMessage.value = false, 5000);
	} else {
		uploadFiles(acceptFiles); // saveFiles as callback
	}
}

const { getRootProps, getInputProps, isDragActive } = useDropzone({
		multiple: false,
		accept: ["application/pdf", "application/x-pdf"],
		onDrop,
	});

const formClass = isDragActive ? 'pdf-upload-dropzone-active' : 'pdf-upload-dropzone'

</script>
<template>
	<div :class="formClass">
		<div v-bind="getRootProps()">
			<input v-bind="getInputProps()" />
			<h2>Try it out right now!</h2>
			<p v-if="isDragActive">Drop the files here... consider using v-show for perf boost, but it doesn tmatter at this
				scale</p>
			<p v-else>Drag 'n' drop PDF file here, or click to select</p>
		</div>
	</div>
	<div v-if="showRejectionMessage" class="pdf-upload-dropzone-rejection">Unfortunately it only works with PDFs</div>
</template>

<style scoped>
.drop-container {
	padding-top: 24px;
}

.pdf-upload-dropzone,
.pdf-upload-dropzone-active {
	border: var(--default_line_thickness) solid var(--base_color_brand_accent_half);
	border-radius: var(--radius-4);
	cursor: pointer;
}

.pdf-upload-dropzone-active {
	outline: 3px dashed var(--color_dropzone_border_hover);
}
</style>
