import { createApp, h } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'


///* quasar icons used by default in components
import '@quasar/extras/material-icons/material-icons.css'
import { Quasar } from 'quasar'
import 'quasar/dist/quasar.css'
import App from './App.vue'
import {router} from './routing/router'

const app = createApp({	render: () => h(App)})
app.use(Quasar, {
	plugins: {}, // import Quasar plugins and add here
})
app.use(router)
app.mount('#app')