<script setup lang="ts">
import { ref } from 'vue'
import { useUploadResponse } from '../composition/useUploadResponse'

const storage = useUploadResponse();
const html = ref(storage.state.response.html);

</script>

<template>
	<div>
		<h1>this is reader</h1>
		<div v-html="html">
		</div>
	</div>
</template>