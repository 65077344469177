
import { reactive } from 'vue'
import { UploadResponse } from '../data/UploadResponse.js'

const state = reactive<{'response': UploadResponse}>({response: null});

export const useUploadResponse = () => {
	const setResponse = (response: UploadResponse) => {
		state.response = response;
	}
	return {
		state,
		setResponse
	}
}
