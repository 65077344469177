<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import LineScaler from '../components/LineScaler.vue';
import FileUpload from '../components/FileUpload.vue';
import '../css/landing.css' // import in css
import Reader from '../components/Reader.vue';
import { ApiRoutes, Routes } from '../routing/Routes';
import { useUploadResponse } from '../composition/useUploadResponse';
import { useRouter } from 'vue-router'
import { UploadResponse } from '../data/UploadResponse';

const homeRoute = ref(Routes.Home)
const splitterModel = ref(50)

const width = ref(400);
// we are using QResizeObserver to keep
// this example mobile-friendly
const onResize = (info: { width: number }) => {
	width.value = info.width
}

const splitterStyle = computed(() => ({
	width: width.value + 'px'
}))

onMounted(async () => {
	console.log(`The app is now mounted.`);
})

const router = useRouter();

async function onFileUpload(data: FormData) {
	console.log('onFileUpload');
	const response = await fetch(ApiRoutes.Upload, {
		method: "POST",
		body: data 
	});
	
	let responseJson = <UploadResponse> await response.json();

	const storage = useUploadResponse();
	storage.setResponse(responseJson);
	router.push({ path: Routes.Reader });
}

</script>

<template>
	<router-view></router-view>
	<header>
		<router-link :to="homeRoute">
			<img src="/logo.png" class="logo" alt="textjoint logo" />
		</router-link>
	</header>
	<article class="main">
		<LineScaler>
			<h1 class="hero">How do you like your reading?</h1>
		</LineScaler>
		<h2 class="hero-subtitle">As PDF a.k.a “a paper simulator” or <span style="color: var(--base_color_brand_primary_text)">responsive and legible text.</span></h2> 
		<!-- <h1 class="hero">How do you like your reading?</h1> -->
		<q-resize-observer @resize="onResize" :debounce="0" />

		<q-splitter v-model="splitterModel" unit="%" class="comparison" before-class="side left" after-class="side right"
			separator-class="separator" :limits="[10, 90]" :style="splitterStyle">
			<template v-slot:before>
				<!-- <h2 class="hero-subtitle">As PDF a.k.a “a paper simulator”</h2> -->
				<object class="panel" data="/example-2.pdf#view=fitW&scrollbar=0&toolbar=0&navpanes=0&scrollbar=0"
					type="application/pdf">
				</object>
			</template>
			<template v-slot:separator>
				<div class="icon">&LeftRightArrow;</div>
				<!-- <q-avatar color="green" text-color="grey" size="40px" icon="drag_indicator" /> -->
			</template>
			<template v-slot:after>
				<!-- <h2 class="hero-subtitle">or responsive and legible text.</h2> -->
				<Reader class="panel">
					<h1>This is the slot content</h1>
					<h2>This is subtitle</h2>
					<p class="hg">regular content of the page goes here</p>
				</Reader>
			</template>
		</q-splitter>

		<div class="drop-container">
			<FileUpload @on-file="onFileUpload" />
		</div>
	</article>
	<div class="more-stuff">
		<a href="/reader.html">go to reader</a>
		<!-- <div class="q-gutter-md row items-start">
			<q-date v-model="date" />
		</div> -->
	</div>
</template>

<style scoped>

.more-stuff {
	background-color: azure;
	height: 600px;
}
</style>