import { createRouter, createWebHistory } from 'vue-router'
import { Routes } from './Routes'

import LandingView from '../views/LandingView.vue';
import ReaderView from '../views/ReaderView.vue';


const routes = [
	{ path: Routes.Home, component: LandingView },
	{ path: Routes.Reader, component: ReaderView },
]

export const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: routes
})
